// Dependencies
import React, { FC, Fragment } from "react";
import styled from "styled-components";

interface ListProps {
  details: string[];
  header?: string;
  smallMargin?: boolean;
  isBold?: boolean;
  withGap?: boolean;
}

const list: FC<ListProps> = ({
  details,
  header,
  smallMargin,
  isBold,
  withGap
}) => {
  return (
    <Fragment>
      {header ? <ListTitle>{header}</ListTitle> : null}
      {details ? (
        <FeatureList smallMargin={smallMargin}>
          {details.map((content, index) => {
            return (
              <FeatureListItem isBold={isBold} key={index} withGap={withGap}>
                {content}
              </FeatureListItem>
            );
          })}
        </FeatureList>
      ) : null}
    </Fragment>
  );
};

export default list;

const FeatureList = styled.ul<{ smallMargin: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom: ${props =>
    props.smallMargin ? props.theme.space[5] : props.theme.space[8]}px;
`;

export const ListTitle = styled.h2`
  font-size: ${props => props.theme.fontSizes[2]}px;
  color: ${props => props.theme.colors.white};
  letter-spacing: 0.1rem;
  font-weight: 700;
`;

const FeatureListItem = styled.li<{ isBold: boolean; withGap: boolean }>`
  padding-left: ${props =>
    props.withGap ? props.theme.space[5] : props.theme.space[3]}px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: ${props => (props.isBold ? "bold" : "normal")};
  color: ${props => props.theme.colors.white};
  letter-spacing: 0.1rem;
  position: relative;
  margin-top: ${props =>
    props.withGap ? props.theme.space[2] : props.theme.space[3]}px;
  ::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary};
    position: absolute;
    left: ${props => (props.withGap ? 15 : 0)}px;
    top: 5px;
  }
`;
