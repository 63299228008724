// Dependencies
import React, { FC } from "react";
import styled from "styled-components";
import Link from "gatsby-link";
import GraphImg from "graphcms-image";

interface PackageTileProps {
  name: string;
  slug: string;
  thumbnail: any;
  course?: boolean;
}

const packageTile: FC<PackageTileProps> = ({
  course,
  name,
  slug,
  thumbnail
}) => {
  return (
    <TileContainer>
      <Link to={slug}>
        <ImageContainer>
          <GraphImg
            image={{
              handle: thumbnail.handle,
              width: 250,
              height: 250 / (thumbnail.width / thumbnail.height)
            }}
            blurryPlaceholder={false}
            withWebp
          />
        </ImageContainer>
        <NameContainer>
          <StyledName>{name}</StyledName>
        </NameContainer>
      </Link>
    </TileContainer>
  );
};

export default packageTile;

const TileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.space[4]}px ${props => props.theme.space[3]}px;
  @media (min-width: ${props => props.theme.breakpoints[3]}) {
    margin: ${props => props.theme.space[6]}px
      ${props => props.theme.space[5]}px;
  }
`;

const ImageContainer = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 50%;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.space[3]}px;
`;

const StyledName = styled.h3`
  width: 100%;
  text-align: center;
  max-width: 250px;
  font-size: ${props => props.theme.fontSizes[5]}px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  color: ${props => props.theme.colors.white};
  text-transform: uppercase;
`;
