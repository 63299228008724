// Dependencies
import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Fade from "react-reveal";

// Components
import { Container } from "./index";
import PackageSection from "../components/packageSection";
import SEO from "../components/seo";

const PakietyPage = ({ data }) => {
  const categories = data.cms.packageCategories;

  const packages = data.cms.packages;

  const filterByCategory = (category, weapons) => {
    const filteredWeapons = weapons.filter((weapon) => {
      const shouldInclude = weapon.packageCategories.find(
        (wepCategory) => wepCategory.packageCategoryId === category
      );
      return shouldInclude;
    });
    return filteredWeapons;
  };

  return (
    <>
      <Fade>
        <Container>
          <PageTitle>{"pakiety strzeleckie"}</PageTitle>
          {categories.map((category, index) => {
            return (
              <PackageSection
                slug={"/pakiety/"}
                title={category.name}
                packages={filterByCategory(
                  category.packageCategoryId,
                  packages
                )}
                key={index}
              />
            );
          })}
        </Container>
      </Fade>
      <SEO />
    </>
  );
};

export default PakietyPage;

export const PageTitle = styled.h1`
  text-align: center;
  margin-top: ${(props) => props.theme.space[4]}px;
  margin-bottom: ${(props) => props.theme.space[6]}px;
  font-size: ${(props) => props.theme.fontSizes[6]}px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;

export const query = graphql`
  query {
    cms {
      packages(stage: PUBLISHED) {
        packageId
        slug
        title
        image {
          handle
          width
          height
        }
        packageCategories {
          packageCategoryId
        }
        body
        price
        priceFrom
        descriptionList
        recommendedPackages
      }
      packageCategories {
        packageCategoryId
        name
      }
    }
  }
`;
