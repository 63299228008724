// Dependencies
import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Fade from "react-reveal";

// Components
import { Container } from "./index";
import CourseSection from "../components/courseSection";
import SEO from "../components/seo";
import { PageTitle } from "./pakiety";

const CoursesPage = ({ data }) => {
  const courses = data.cms.courses;
  return (
    <>
      <StyledFade>
        <Container>
          <PageTitle>{"kursy i szkolenia"}</PageTitle>
          {courses.map((course) => {
            return (
              <CourseSection
                key={course.courseId}
                pictureLeft={course.pictureLeft}
                title={course.name}
                text={course.bodyShort}
                linkTo={`/szkolenia/${course.slug}`}
                image={course.image}
                price={course.price}
                priceFrom={course.priceFrom}
                ctaBadge={course.ctaButtonContent}
              />
            );
          })}
        </Container>
        <SEO />
      </StyledFade>
    </>
  );
};

export default CoursesPage;

const StyledFade = styled(Fade)`
  display: flex;
  justify-content: center;
`;

export const query = graphql`
  query {
    cms {
      courses(stage: PUBLISHED) {
        name
        body
        bodyShort
        price
        priceFrom
        descriptionList
        recommendedCourses
        ctaButtonContent
        courseId
        slug
        pictureLeft
        image {
          handle
          width
          height
        }
      }
    }
  }
`;
