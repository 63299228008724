// Dependencies
import React, { FC } from "react";
import styled from "styled-components";
import GraphImg from "graphcms-image";

// Components
import Description from "../components/description";
import Target from "./target";

interface CourseSectionProps {
  pictureLeft?: Boolean;
  buyButton?: Boolean;
  title: string;
  text: string[];
  linkTo: string;
  image: any;
  price: number;
  isPackage?: Boolean;
  ctaBadge?: string;
  priceFrom?: boolean;
}

const CourseSection: FC<CourseSectionProps> = ({
  pictureLeft,
  buyButton,
  title,
  text,
  linkTo,
  image,
  price,
  priceFrom,
  isPackage,
  ctaBadge,
}) => {
  return (
    <SectionContainer>
      <GeneralInfoContainer pictureLeft={pictureLeft}>
        <Pictures pictureLeft={pictureLeft}>
          <PicturesContainer pictureLeft={pictureLeft}>
            <StyledTarget>
              <Target />
            </StyledTarget>
            <ImageContainer pictureLeft={pictureLeft}>
              <GraphImg
                image={{
                  handle: image.handle,
                  width: 350,
                  height: 350 / (image.width / image.height),
                }}
                withWebp
              />
            </ImageContainer>
          </PicturesContainer>
        </Pictures>
        <Description
          linkTo={linkTo}
          price={price}
          priceFrom={priceFrom}
          title={title}
          text={text}
          buyButton={buyButton}
          isPackage={isPackage}
          ctaBadge={ctaBadge}
        />
      </GeneralInfoContainer>
    </SectionContainer>
  );
};

export default CourseSection;

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.theme.space[4]}px 0;
`;

const GeneralInfoContainer = styled.div<{ pictureLeft: Boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: ${(props) => (props.pictureLeft ? "row" : "row-reverse")};
  }
`;

const Pictures = styled.div<{ pictureLeft: Boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.theme.space[4]}px 0;
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    flex: 1;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex: 1;
    margin-right: ${(props) => (props.pictureLeft ? "24px" : "0")};
    margin-left: ${(props) => (props.pictureLeft ? "0" : "24px")};
  }
`;

const PicturesContainer = styled.div<{ pictureLeft: Boolean }>`
  width: 270px;
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.pictureLeft ? "row" : "row-reverse")};
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 420px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 525px;
  }
`;

const StyledTarget = styled.div`
  width: 180px;
  height: 180px;
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 280px;
    height: 280px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 350px;
    height: 350px;
  }
`;

const ImageContainer = styled.div<{ pictureLeft: Boolean }>`
  position: absolute !important;
  top: 0;
  left: ${(props) => (props.pictureLeft ? "33%" : 0)};
  object-fit: contain;
  border-radius: 50%;
  z-index: 2;
  overflow: hidden;
  width: 180px;
  height: 180px;
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 280px;
    height: 280px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 350px;
    height: 350px;
  }
`;
