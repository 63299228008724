// Dependencies
import React, { FC } from "react";
import styled from "styled-components";

// Components
import PackageTile from "./packageTile";

interface PackageSectionProps {
  title?: string;
  course?: boolean;
  slug: string;
  packages: {
    id: number;
    slug: string;
    title?: string;
    name?: string;
    image: string;
    categories: number[];
    body: string;
    bodyShort: string;
    price: number;
    descriptionList: string[];
    recommendedPackages: number[];
  }[];
}

const packageSection: FC<PackageSectionProps> = ({
  course,
  title,
  packages,
  slug
}) => {
  return (
    <SectionContainer>
      {title ? <StyledTitle>{title.toUpperCase()}</StyledTitle> : null}
      <StyledTileContainer>
        {packages.map((p, index) => {
          return (
            <PackageTile
              course={course}
              name={p.title || p.name}
              slug={`${slug}${p.slug}`}
              thumbnail={p.image}
              key={index}
            />
          );
        })}
      </StyledTileContainer>
    </SectionContainer>
  );
};

export default packageSection;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.space[5]}px;
`;

const StyledTileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: center;
  @media (min-width: ${props => props.theme.breakpoints[3]}) {
    margin: 0 -${props => props.theme.space[5]}px;
  }
`;

const StyledTitle = styled.h3`
  width: 100%;
  padding: ${props => props.theme.space[2]}px 0;
  border-bottom: 4px solid ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes[5]}px;
  font-weight: bold;
  letter-spacing: 0.1rem;
`;
