// Dependencies
import React, { FC } from "react";
import styled from "styled-components";

// Components
import Button from "../components/button";
// import BuyButton from "../components/buyButton";
import List from "../components/list";

interface DescriptionProps {
  title: string;
  text: string[];
  price: number;
  linkTo: string;
  buyButton?: Boolean;
  isPackage?: Boolean;
  ctaBadge?: string;
  priceFrom?: boolean;
}

const StyledMonkawButton = styled.a`
  display: block;
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  font-weight: 700;
  border: 2px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  padding: ${(props) => props.theme.space[3]}px
    ${(props) => props.theme.space[2]}px;
  position: relative;
  overflow: hidden;
  transition: 0.4s ease;
  z-index: 2;
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: ${(props) => props.theme.space[3] + 4}px
      ${(props) => props.theme.space[6]}px;
  }
`;

const description: FC<DescriptionProps> = ({
  title,
  text,
  price,
  priceFrom,
  linkTo,
  buyButton,
  isPackage,
  ctaBadge,
}) => {
  return (
    <DescriptionContainer>
      <Title>{title}</Title>
      {isPackage ? (
        <List details={text} smallMargin></List>
      ) : (
        <>
          {text.map((content, index) => {
            return <Text key={index}>{content}</Text>;
          })}
        </>
      )}
      <Price>
        {priceFrom === true && <span style={{ marginRight: "5px" }}>OD</span>}
        {price}
        <span> PLN</span>
      </Price>
      <ButtonContainer>
        {buyButton ? (
          // <BuyButton
          //   linkTo={""}
          //   content={isPackage ? "kup pakiet" : "kup kurs"}
          // />
          <StyledMonkawButton href="mailto:recepcja@b7strzelnica.pl">
            skontaktuj się z nami
          </StyledMonkawButton>
        ) : (
          <Button
            linkTo={linkTo}
            content={ctaBadge ? ctaBadge : "zobacz więcej"}
          />
        )}
      </ButtonContainer>
    </DescriptionContainer>
  );
};

export default description;

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  letter-spacing: 0.15rem;
  margin: ${(props) => props.theme.space[4]}px 0;
  text-transform: uppercase;
`;

export const Text = styled.p`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.white};
  letter-spacing: 0.1rem;
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;

const Price = styled.p`
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  letter-spacing: 0.1rem;
  span {
    font-size: ${(props) => props.theme.fontSizes[2]}px;
  }
`;

const ButtonContainer = styled.div`
  margin: ${(props) => props.theme.space[5]}px 0;
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    margin: ${(props) => props.theme.space[7]}px 0;
  }
`;
